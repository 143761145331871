
@import "./themes/generated/variables.base.scss";

$base-font-size: 13px;
$base-font-size-medium: 15px;
$base-font-size-large: 20px;
$base-font-size-x-large: 25px;
$base-font-size-xx-large: 30px;

$font-family-medium: PoppinsMedium;
$font-family-semi-bold: PoppinsSemiBold;
$font-family-bold: PoppinsBold;

$base-second-text-color: #707070;

$base-primary-color: #30377B;
$base-unselected-color: #CCCCCC;

$base-info: rgb(0, 122, 255);

$base-second-bg: #FBFBFB;
$base-third-bg: #E4E4E4;
$base-dark-bg: #373737;

$base-second-border-color: #CCCCCC;

$base-ripple-light-color: rgba(255,255,255,.2);
$base-ripple-dark-color: rgba(84, 84, 84, 0.2);

$card-border-color: $base-border-color;
$card-border-radius: 8px;
$card-block-border-radius: 16px;

$toast-color: var(--base-text-color);
$toast-background-color: white;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1640px
);

$grid-gutter-width: 15px !default;
$grid-gutter-single: $grid-gutter-width / 2;
$gutters: (
  0: 0,
  1: $grid-gutter-width * .25,
  2: $grid-gutter-width * .5,
  3: $grid-gutter-width,
  4: $grid-gutter-width * 1.5,
  5: $grid-gutter-width * 2,
);

$space-block-left: $grid-gutter-width * 1.5;
$space-block-right: $grid-gutter-width * 1.5;
$space-block-left-small: $grid-gutter-width;
$space-block-right-small: $grid-gutter-width;
