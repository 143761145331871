$base-accent: #FFBE26;
$base-font-family: Poppins;
$base-text-color: #545454;
$base-label-color: #545454;
$base-icon-color: rgba(84, 84, 84, 0.54);
$base-spin-icon-color: rgba(84, 84, 84, 0.54);
$base-bg: #FFFFFF;
$base-border-color: #E2E2E2;
$base-success: #00CF30;
$base-warning: #FF7F00;
$base-danger: #FF3737;
$base-hover-color: #545454;
$base-hover-bg: #f5f5f5;
$base-focus-color: #fff;
$base-focus-bg: rgba(0, 0, 0, 0.04);
$base-link-color: #6a6a6a;
$base-border-radius: 10px;
$button-normal-color: #545454;
$button-normal-bg: #E2E2E2;
$button-normal-hover-bg: #cecece;
$button-normal-focused-bg: #cecece;
$button-normal-active-bg: #969696;
$button-default-color: #fff;
$button-default-bg: #FFBE26;
$button-default-hover-bg: #faaf00;
$button-default-focused-bg: #faaf00;
$button-default-active-bg: #c28800;
$button-danger-color: #fff;
$button-danger-bg: #FF3737;
$button-danger-hover-bg: #ff0c0c;
$button-danger-focused-bg: #ff0c0c;
$button-danger-active-bg: #d30000;
$button-success-color: #fff;
$button-success-bg: #00CF30;
$button-success-hover-bg: #00a426;
$button-success-focused-bg: #00a426;
$button-success-active-bg: #006c19;
$list-group-color: #545454;
$list-border-color: #E2E2E2;
$list-group-header-bg: rgba(255, 255, 255, 0);
$list-normal-color: #545454;
$list-expanded-group-color: #FFBE26;
$list-item-hover-bg: #f5f5f5;
$list-item-selected-bg: rgba(0, 0, 0, 0.12);
$list-item-active-bg: #cccccc;
$fieldset-field-label-color: #c9c9c9;
$button-group-normal-selected-color: #545454;
$button-group-normal-selected-bg: rgba(0, 0, 0, 0.18);
$button-group-default-selected-color: #FFBE26;
$button-group-default-selected-bg: rgba(255, 190, 38, 0.22);
$button-group-danger-selected-color: #FF3737;
$button-group-danger-selected-bg: rgba(255, 55, 55, 0.22);
$button-group-success-selected-color: #00CF30;
$button-group-success-selected-bg: rgba(0, 207, 48, 0.22);
$scrollable-scroll-bg: rgba(191, 191, 191, 0.7);
$tabs-tab-color: rgba(84, 84, 84, 0.54);
$tabs-tab-selected-color: rgba(0, 0, 0, 0.54);
$tabs-selected-tab-bottom-border-color: #FFBE26;
$tabs-tab-bg: #FFFFFF;
$tabs-hovered-tab-bg-color: #ededed;
$badge-color: #fff;
$badge-bg: #FFBE26;
$overlay-content-bg: #FFFFFF;
$overlay-shader-bg: rgba(0, 0, 0, 0.32);
$textbox-search-icon-color: rgba(84, 84, 84, 0.54);
$texteditor-color: #545454;
$texteditor-placeholder-color: #e8e8e8;
$texteditor-bg: rgba(84, 84, 84, 0.04);
$texteditor-focused-bg: rgba(84, 84, 84, 0.04);
$texteditor-border-color: rgba(84, 84, 84, 0.42);
$texteditor-hover-border-color: #545454;
$texteditor-focused-border-color: #FFBE26;
$texteditor-hover-bg: rgba(84, 84, 84, 0.07);
$calendar-header-color: #bababa;
$calendar-cell-other-color: #d1d1d1;
$calendar-cell-selected-bg: #FFBE26;
$calendar-shevron-icon-color: #FFBE26;
$dropdowneditor-icon-active-color: rgba(84, 84, 84, 0.54);
$load-indicator-segment-bg-color: #FFBE26;
$treeview-focused-bg: #f5f5f5;
$menu-color: #c9c9c9;
$menu-item-selected-bg: white;
$selectbox-list-bg: #FFFFFF;
$toolbar-bg: #FFFFFF;
$popup-title-bg: transparent;
$toast-color: rgba(255, 255, 255, 0.87);
$toast-info-bg: rgba(0, 0, 0, 0.87);
$toast-warning-bg: #FF7F00;
$toast-error-bg: #FF3737;
$toast-success-bg: #00CF30;
$progressbar-bg: rgba(255, 190, 38, 0.3);
$progressbar-range-bg: #FFBE26;
$progressbar-label-color: #FFBE26;
$loadpanel-content-bg: #FFFFFF;
$datagrid-columnchooser-item-color: rgba(84, 84, 84, 0.54);
$datagrid-columnchooser-font-weight: 500;
$datagrid-drag-header-border-color: rgba(255, 190, 38, 0.5);
$datagrid-selection-bg: rgba(255, 190, 38, 0.04);
$datagrid-row-selected-border-color: #E2E2E2;
$datagrid-row-selected-color: #545454;
$datagrid-row-focused-color: #fff;
$datagrid-row-focused-bg: #ffcb51;
$datagrid-menu-icon-color: rgba(170, 170, 170, 0.54);
$datagrid-cell-modified-border-color: rgba(0, 207, 48, 0.32);
$datagrid-row-invalid-faded-border-color: rgba(255, 55, 55, 0.32);
$datagrid-group-row-color: rgba(84, 84, 84, 0.54);
$datagrid-group-row-bg: white;
$datagrid-search-color: #fff;
$datagrid-search-bg: #FFBE26;
$datagrid-row-error-color: #fff;
$datagrid-row-error-bg: #ff8787;
$datagrid-focused-border-color: #ffcb51;
$datagrid-editor-bg: rgba(255, 255, 255, 0);
$datagrid-row-alternation-bg: whitesmoke;
$datagrid-summary-color: rgba(84, 84, 84, 0.7);
$pager-page-selected-color: #fff;
$pager-page-selected-bg: #FFBE26;
$form-field-item-color: #c9c9c9;
$form-group-border-color: #E2E2E2;
$filterbuilder-text-color: #545454;
$filterbuilder-text-focus-color: #fff;
$filterbuilder-plus-icon-color: rgba(0, 207, 48, 0.7);
$filterbuilder-remove-icon-color: rgba(255, 55, 55, 0.7);
$filterbuilder-group-operation-color: rgba(255, 55, 55, 0.3);
$filterbuilder-item-field-color: rgba(255, 190, 38, 0.3);
$filterbuilder-item-operator-color: rgba(0, 207, 48, 0.3);
$filterbuilder-item-value-color: rgba(226, 226, 226, 0.5);
